<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              La Formation Pro
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Mot de passe oublié ?
          </p>
          <p class="mb-2">
            Merci de saisir votre adresse e-mail afin de reçevoir un e-mail pour réinitialiser votre mot de passe
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Adresse e-mail"
              :rules="emailRule"
              class="mb-3"
            ></v-text-field>

            <v-btn
              block
              :disabled="disableBtn"
              color="primary"
              class="mt-6"
              @click="forgotPass"
            >
              Réinitialiser le mot de passe
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <a
            color="primary"
            class="mt-5"
            @click="$router.push({name:'login'})"
          >
            Retour à l'identification
          </a>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      disableBtn: false,
      valid: true,
      email: '',
      emailRule:
        [v => !v || /.+@.+/.test(v) || 'L\'adresse e-mail doit être valide'],
    }
  },
  mounted() {
    this.$store.dispatch('logout')
  },
  setup() {
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    forgotPass() {
      if (this.$refs.form.validate()) {
        this.disableBtn = true
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/forgot-password/`, { email: this.email })
          .then(res => {
            if (res.status === 200) {
              this.currentPassword = ''
              this.newPassword = ''
              this.cPassword = ''
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Vous allez reçevoir un e-mail pour réinitialiser votre mot de passe.',
                value: true,
              })
            }
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Aucun utilisateur trouvé avec cette adresse',
                value: true,
              })
            } else {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors de la mise à jour du mot de passe',
                value: true,
              })
            }
            console.log(err)
          })
          .finally(() => {
            this.disableBtn = false
          })
      } else {
        this.$refs.form.validate()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
